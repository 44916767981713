<template>
  <div>
    <h2>公司动态</h2>
    <div class="">
      <div
        shadow="hover"
        class="bb"
        style="align-items: center"
        v-for="(item, index) in companyList"
        :key="index"
        @click="goDetail(item, index)"
      >
        <template>
          <img
            height="80px"
            style=""
            :src="'/com/file/fileDownLoad?filePath=' + item.articleImage"
            alt=""
             :onerror="smallImgError"
          />
          <div class="right" style="align-items: center">
            <!-- <h3 style="margin-bottom: 8px">
              {{ item.content }}
            </h3> -->
            <div class="title" v-html="item.content"></div>
            <p>{{ item.releaseTime }}</p>
          </div>
        </template>
      </div>
    </div>

    <div class="pagination">
      <el-pagination
        background
        :hide-on-single-page="tableQuery.total <= 9"
        layout="prev, pager, next"
        :total="tableQuery.total"
        @current-change="handleChange"
        :current-page.sync="tableQuery.current"
        :page-size="tableQuery.size"
      ></el-pagination>
    </div>
  </div>
</template>
<script>
import {
  getVideo,
  pageArticleInfo, //公司动态
} from "@/api";
export default {
  name: "CompanyStatic",
  data() {
    return {
      companyList: [],
      // 查询参数
      tableQuery: {
        total: 0,
        current: 1,
        size: 10,
        type: 4,
        subjectId: 0,
      },
    };
  },
  mounted() {
    // this.getVideo();
    this.pageArticleInfo();
  },
  methods: {
    goDetail(item) {
      // console.log(11);
      this.$router.push({
        path: "/information/CompanyStatic/detail",
        query: {
          id: item.id,
        },
      });
    },
    async pageArticleInfo() {
      let res = await pageArticleInfo(this.tableQuery);
      this.companyList = res.data.records;
      this.tableQuery.total = res.data.total;
    },
    handleChange(page) {
      this.tableQuery.current = page;
      this.pageArticleInfo();
    },
  },
};
</script>
<style lang="less" scoped>
h2 {
  text-align: center;
  margin-bottom: 50px;
}
img {
  object-fit: cover;
  height: 80px;
  width: 110px;
  position: absolute;
}
.right {
  height: 80px;
  line-height: 40px;
  margin-left: 120px;
  .title {
    // font-size: 18px;
    // font-family: Microsoft YaHei;
    // font-weight: bold;
    // color: #333333;
    // overflow: hidden;
    // white-space: nowrap;
    // text-overflow: ellipsis;
    /deep/ p {
      font-size: 18px;
      font-family: Microsoft YaHei;
      font-weight: bold;
      color: #333333;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
}
.bb {
  cursor: pointer;
  padding-bottom: 20px;
  border-bottom: 1px solid #f1f1f1;
}
</style>